import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Home | Patrick Ndayambaje </title>
            </Helmet>
            <div className="header__text-box row">
                <div className="header__text">
                    <h1 className="heading-primary">
                        <span>Patrick Ndayambaje</span>
                    </h1>
                    <p>A Software Engineer based in Kigali, Rwanda.</p>
                    <Link to="/contact" className="btn bg-primary">
                        Get in touch
                    </Link>
                </div>
            </div>
        </>
    );
};

export default HomePage;
