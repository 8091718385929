import { useRoutes } from 'react-router-dom';
import AppLayout from '~/components/layouts/app/AppLayout';
import AboutPage from '~/pages/about/AboutPage';
import CompaniesPage from '~/pages/companies/CompaniesPage';
import ContactPage from '~/pages/contact/ContactPage';
import HomePage from '~/pages/home/HomePage';
import NotFoundPage from '~/pages/notfound/NotFoundPage';
import ProjectsPage from '~/pages/projects/ProjectsPage';

export const Routes = () => {
    return useRoutes([
        {
            path: '/',
            element: <AppLayout />,
            children: [
                {
                    path: '/',
                    index: true,
                    element: <HomePage />,
                },
                {
                    path: '/about',
                    element: <AboutPage />,
                },
                {
                    path: '/companies',
                    element: <CompaniesPage />,
                },
                {
                    path: '/projects',
                    element: <ProjectsPage />,
                },
                {
                    path: '/contact',
                    element: <ContactPage />,
                },
            ],
        },
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ]);
};
