import React, { useState, useEffect } from 'react';
import { ICONS } from '~/assets/icons';

export function BackToTopButton(): JSX.Element {
    const [isBackToTopRendered, setIsBackToTopRendered] =
        useState<boolean>(false);

    useEffect(() => {
        const alterStyles = () => {
            const backToTopButton = document.querySelector(
                '.back-to-top'
            ) as HTMLElement | null;
            if (backToTopButton) {
                backToTopButton.style.visibility = isBackToTopRendered
                    ? 'visible'
                    : 'hidden';
                backToTopButton.style.opacity = isBackToTopRendered ? '1' : '0';
                backToTopButton.style.transform = isBackToTopRendered
                    ? 'scale(1)'
                    : 'scale(0)';
            }
        };

        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setIsBackToTopRendered(true);
            } else {
                setIsBackToTopRendered(false);
            }
        });

        alterStyles();
    }, [isBackToTopRendered]);

    return (
        <a href="#top" className="back-to-top" title="Back to Top">
            <img
                src={ICONS.ArrowUp}
                alt="Back to Top"
                className="back-to-top__image"
            />
        </a>
    );
}
