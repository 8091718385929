import React from 'react';
import { Helmet } from 'react-helmet-async';

const ContactPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Contact | Patrick Ndayambaje </title>
            </Helmet>
            <section className="contact" id="contact">
                <div className="row">
                    <h2>Get in Touch</h2>
                    <div className="contact__info">
                        <p>
                            Looking for a software engineer? Need consultation
                            or have any questions? Or perhaps just want to say
                            'Hi 👋'? Feel free to reach out—I’m always happy to
                            help. I’ll do my best to respond promptly. The
                            quickest way to contact me is via email.
                        </p>
                        <a
                            href="mailto:p.ndayambaje.work@gmail.com"
                            className="btn bg-primary"
                        >
                            p.ndayambaje.work@gmail.com
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactPage;
