import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ProjectCard } from '~/components/common/project-card/ProjectCard';
import { companies } from '~/data';

const CompaniesPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Portfolio | Patrick Ndayambaje</title>
            </Helmet>
            <div className="row h-full min-h-full ">
                <h2>Companies</h2>
                <div className="grid grid-cols-1 gap-20 px-10 lg:grid-cols-2">
                    {companies.map((project, idx) => {
                        return (
                            <ProjectCard
                                key={idx}
                                title={project.name}
                                description={project.description}
                                src={project.image}
                                href={project.official_link}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CompaniesPage;
