import { PROJECTS } from '~/assets/images/projects';
import { WorkType } from './projects';

export const companies: WorkType[] = [
    {
        name: 'NaviGO',
        description:
            'Led the full-stack development of innovative web applications, optimizing user interfaces for responsiveness and enhancing back-end functionality using modern technologies. Collaborated closely with cross-functional teams to integrate APIs, manage databases, and implement scalable, secure solutions.',
        image: PROJECTS.NaviGO,
        official_link: 'https://www.navigo.rw',
    },
    {
        name: 'Rwand TVET Board',
        description:
            'Designed and implemented interactive and visually engaging interfaces for both websites and applications. Played a key role in developing UI components, layouts, and dynamic elements to enhance usability and user satisfaction.',
        image: PROJECTS.RTB,
        official_link: 'https://rtb.gov.rw',
    },
    {
        name: 'vSecure LLC',
        description:
            'Architected a micro-frontend architecture for complex systems, ensuring seamless user experiences across different applications. Focused on scalability and maintainability, delivering reliable and efficient software solutions.',
        image: PROJECTS.vSecure,
        official_link: 'https://italos.io',
    },
    {
        name: 'Select Safaris Africa',
        description:
            'Developed and maintained a digital platform for Select Safaris Africa, focusing on booking systems, user management, and third-party integrations. Contributed to the company’s mission of sustainable tourism by building features that support their community-focused initiatives.',
        image: PROJECTS.SSA,
        official_link: 'https://www.selectsafarisafrica.com',
    },
    {
        name: 'Ishema Hub - Waka',
        description:
            'Full-stack development of cross-platform web applications, focusing on efficient data management, secure server-side logic, and responsive front-end designs. Consistently delivered high-quality solutions by utilizing best practices and modern technologies.',
        image: PROJECTS.Waka,
        official_link: 'https://wakaglobal.com',
    },
    {
        name: 'Top5Brokers',
        description:
            'Integrated third-party tools and financial data feeds into the platform to enhance functionality and provide actionable insights. Improved the overall user experience by optimizing both front-end performance and back-end processing.',
        image: PROJECTS.Top5Brokers,
        official_link: 'https://brokerchooser.vercel.app/',
    },
    {
        name: 'GEEPX',
        description:
            'Drove the end-to-end development of web and mobile applications, with a focus on creating dynamic and intuitive user experiences. Leveraged React for front-end development and built robust server-side solutions with Nest.js, ensuring high performance and security.',
        image: PROJECTS.GEEPX,
        official_link: 'https://www.geepx.com',
    },
    {
        name: 'DotPharma',
        description:
            'Significantly improved application performance by optimizing code, resolving critical issues, and ensuring stability and scalability. Focused on delivering a seamless user experience and ensuring the reliability of the platform.',
        image: PROJECTS.DotPharma,
        official_link: 'https://dotpharma.com',
    },
];
