import DotPharma from './dotpharma.png';
import FoodPlanet from './foodplanet.png';
import Shurl from './shurl.png';
import SpaceX from './spacex.png';
import Top5Brokers from './top5brokers.png';
import Twencon from './twencon.png';
import Waka from './waka.png';
import UziChat from './uzichat.png';
import Lendors from './lendors.png';
import GEEPX from './geepx.png';
import vSecure from './vsecure.png';
import OnCode from './oncode.png';
import NaviGO from './navigo.png';
import RTB from './rtb.png';
import Crypto from './crypto.png';
import RocketShooter from './rocket-shooter.png';
import Resumegen from './resumegen.png';
import SSA from './ssa.png';
import SmartGlasses from './smart-glasses.png';

export const PROJECTS = {
    DotPharma,
    FoodPlanet,
    Shurl,
    SpaceX,
    Top5Brokers,
    Twencon,
    Waka,
    UziChat,
    Lendors,
    GEEPX,
    vSecure,
    OnCode,
    NaviGO,
    RTB,
    Crypto,
    Resumegen,
    RocketShooter,
    SSA,
    SmartGlasses,
};
