import React from 'react';
import { Outlet } from 'react-router-dom';
import FooterComponent from '~/components/common/footer/FooterComponent';
import NavBarComponent from '~/components/common/navbar/NavBarComponent';

const AppLayout: React.FC = () => {
    return (
        <div>
            <div className="header" role="banner" id="top">
                <NavBarComponent />
                <Outlet />
            </div>
            <FooterComponent />
        </div>
    );
};

export default AppLayout;
