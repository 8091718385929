import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center ">
            <h2>404 Page Not Found</h2>
            <Link to={'/'} className="nav__link">
                Home
            </Link>
        </div>
    );
};

export default NotFoundPage;
