import React, { FC, ImgHTMLAttributes, useState } from 'react';
import LoadingImage from '~/components/common/loading-image/LoadingImage';

interface CustomImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const Image: FC<CustomImageProps> = props => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    return (
        <div className={props.className}>
            <img
                style={{ display: hasLoaded ? 'block' : 'none' }}
                onLoad={() => {
                    setHasLoaded(true);
                }}
                {...props}
            />

            {!hasLoaded && <LoadingImage />}
        </div>
    );
};

export default Image;
