import React, { useState } from 'react';
import { Images } from '~/assets/images/personal';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const AboutPage: React.FC = () => {
    const [hasLoaded, setLoaded] = useState<boolean>(false);
    return (
        <>
            <Helmet>
                <title>About | Patrick Ndayambaje</title>
            </Helmet>
            <section className="about" id="about">
                <div className="row">
                    <h2>About Me</h2>
                    <div className="about__content">
                        <div className="about__text">
                            <p className="text-[18px]">
                                I’m a dedicated software engineer who loves
                                solving problems with technology. I have
                                experience building everything from mobile apps
                                to large backend systems. My focus is on
                                creating easy-to-use and reliable solutions that
                                meet real-world needs. I work across full-stack
                                development, cloud integration, and UI/UX design
                                to deliver products that look good and work
                                great. I’m always looking for ways to improve
                                and enjoy working with others to turn ideas into
                                reality.
                                <br />
                                <br />
                                Let’s create something amazing together.
                            </p>
                            <Link to="/contact" className="btn bg-primary">
                                Get in touch
                            </Link>
                        </div>
                        <div className="about__photo-container flex items-center justify-center md:justify-start">
                            <img
                                className="object-fit h-[45rem] w-[45rem] rounded-full object-cover opacity-60 hover:opacity-100"
                                src={Images.ProfileImage}
                                alt="Patrick Ndayambaje"
                                style={{
                                    display: hasLoaded ? 'block' : 'none',
                                }}
                                onLoad={() => {
                                    setLoaded(true);
                                }}
                            />
                            {!hasLoaded && (
                                <div
                                    role="status"
                                    className="h-[45rem] w-[45rem] animate-pulse  space-y-8 rounded-full rtl:space-x-reverse md:flex md:items-center md:space-y-0 md:space-x-8"
                                >
                                    <div className="flex h-full w-full items-center justify-center rounded-full bg-slate-300 dark:bg-slate-700">
                                        <svg
                                            className="h-10 w-10 text-slate-200 dark:text-slate-600"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 18"
                                        >
                                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutPage;
