import Image from '~/components/elements/image/Image';

interface Props {
    src: any;
    title: string;
    description: string;
    href: string;
}

export const ProjectCard = ({ src, title, description, href }: Props) => {
    return (
        <a
            href={href}
            target="_blank"
            className="relative overflow-hidden rounded-lg border border-primary shadow-lg transition-all duration-200"
        >
            <Image
                src={src}
                alt={title}
                width={1000}
                height={1000}
                className="w-full object-contain"
            />

            <div className="relative p-4">
                <h1 className="text-4xl font-semibold text-white">{title}</h1>
                <p className="text-gray-300 mt-2">{description}</p>
            </div>
        </a>
    );
};
