import { PROJECTS } from '~/assets/images/projects';

export type WorkType = {
    name: string;
    description: string;
    official_link: string;
    image: string | undefined;
};

export const projects: WorkType[] = [
    {
        name: 'Smart-Glasses',
        description:
            'An innovative wearable solution for individuals with visual impairments, featuring real-time auditory cues, text-to-speech capabilities, and enhanced contrast settings. This project combines technology and accessibility to empower users to confidently navigate their environment with greater independence.',
        image: PROJECTS.SmartGlasses,
        official_link: 'https://smart-glasses-live.vercel.app',
    },
    {
        name: 'Resumegen',
        description:
            'A web-based resume builder that leverages modern JavaScript frameworks for a dynamic user experience. Allows users to create and customize professional resumes with real-time preview and downloadable formats.',
        image: PROJECTS.Resumegen,
        official_link: 'https://resumegen-mocha.vercel.app',
    },
    {
        name: 'Crypto',
        description:
            'An online platform for cryptocurrency trading, utilizing RESTful APIs for real-time market data. Features secure transaction handling and a user-friendly interface for managing crypto assets and executing trades.',
        image: PROJECTS.Crypto,
        official_link: 'https://crypto-five-snowy.vercel.app/',
    },
    {
        name: 'Rocket Shooter',
        description:
            'A web-based interactive game built using modern web technologies. Incorporates real-time rendering and physics simulation to create engaging gameplay. Optimized for performance and scalability to handle multiple concurrent players.',
        image: PROJECTS.RocketShooter,
        official_link: 'https://rocket-shooter.vercel.app/',
    },
    {
        name: 'OnCode',
        description:
            'A collaborative web-based code editor that supports real-time code sharing and debugging. Implements WebSocket for live collaboration and integrates with various development tools to enhance coding efficiency and team coordination.',
        image: PROJECTS.OnCode,
        official_link: 'https://oncode.vercel.app',
    },
    {
        name: 'Lendors',
        description:
            'A car rental platform developed with a focus on user experience and scalability. Features an intuitive interface for listing and managing vehicles, secure payment processing, and real-time availability tracking.',
        image: PROJECTS.Lendors,
        official_link: 'https://lendors.vercel.app',
    },
    {
        name: 'Twencon',
        description:
            'A real-time social networking application utilizing WebSocket for instant messaging and notifications. Supports dynamic content updates and user interactions, including posts, comments, and friend feedback, with a focus on scalability and responsiveness.',
        image: PROJECTS.Twencon,
        official_link: 'https://twenconi.vercel.app',
    },
    {
        name: 'Shurl',
        description:
            'A URL shortening service that provides an API for generating and managing short links. Implements efficient redirection mechanisms and analytics to track link usage and performance.',
        image: PROJECTS.Shurl,
        official_link: 'https://shurls.vercel.app',
    },
];
