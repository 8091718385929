import Twitter from './twitter.svg';
import LinkedIn from './linkedin.svg';
import GitHub from './github.svg';
import Instagram from './instagram.png';
import Heart from './heart.svg';
import ArrowUp from './arrow-up.svg';
import Menu from './menu.svg';
import Cancel from './cancel.svg';

export const ICONS = {
    Twitter,
    LinkedIn,
    GitHub,
    Heart,
    ArrowUp,
    Instagram,
    Menu,
    Cancel,
};
