import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ICONS } from '~/assets/icons';

type NavLinkType = { path: string; text: string };

const NavBarComponent: React.FC = () => {
    const nav_links: NavLinkType[] = [
  
        {
            path: '/projects',
            text: 'Projects',
        },
        {
            path: '/companies',
            text: 'Companies',
        },
        {
            path: '/about',
            text: 'About',
        },
        {
            path: '/contact',
            text: 'Contact',
        },
    ];

    const [isNavLinkOpen, setIsNavLinkOpen] = useState<boolean>(false);

    const openNavLink = () => {
        setIsNavLinkOpen(true);
    };

    const closeNavLink = () => {
        setIsNavLinkOpen(false);
    };

    const NAV_LINK_ELEMENT = useRef<any>(null);

    useEffect(() => {
        const clickEvent = () => {
            if (!NAV_LINK_ELEMENT.current?.contains(event?.target))
                closeNavLink();
        };
        document.addEventListener('mousedown', clickEvent);
        return () => {
            document.removeEventListener('mousedown', clickEvent);
        };
    }, [NAV_LINK_ELEMENT]);

    return (
        <div className="row">
            <nav
                className="nav flex justify-between gap-[70%] sm:gap-10"
                role="navigation"
            >
                <Link
                    to={'/'}
                    className={`nav__link ${
                        window.location.pathname == '/' &&
                        'underline-offset-[18px]'
                    }`}
                >
                    Home
                </Link>
                <ul className="nav__items hidden gap-10 pt-1 md:flex">
                    {nav_links.map(({ path, text }, index) => (
                        <li className="nav__item" key={index}>
                            <Link
                                to={path}
                                className={`nav__link 
                             ${
                        window.location.pathname == path &&
                                 'nav__link__current underline underline-offset-[18px]'
                        }
                            `}
                            >
                                {text}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="flex h-full items-center justify-center md:hidden">
                    <button
                        onClick={openNavLink}
                        className="flex items-center justify-center"
                    >
                        <img
                            src={ICONS.Menu}
                            alt={'Menu'}
                            className="mt-5 w-12"
                        />
                    </button>
                </div>
            </nav>
            <div
                className={`fixed top-0 right-0 z-50 ${
                    isNavLinkOpen ? 'flex' : 'hidden'
                } h-screen w-[10em] flex-col overflow-auto border-l-2 border-primary bg-[#131313] px-2 py-5 md:hidden`}
                ref={NAV_LINK_ELEMENT}
            >
                <div className="flex h-[4em] w-full items-center justify-center">
                    <button onClick={closeNavLink}>
                        <img
                            src={ICONS.Cancel}
                            alt={'Menu'}
                            className="h-10 w-10"
                        />
                    </button>
                </div>
                <ul className="nav__items flex flex-col items-center justify-center gap-5">
                    {nav_links.map(({ path, text }, index) => (
                        <li key={index}>
                            <Link
                                to={path}
                                onClick={closeNavLink}
                                className={`nav__link 
                             ${
                        window.location.pathname == path &&
                                 'nav__link__current underline underline-offset-[18px]'
                        }
                            `}
                            >
                                {text}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default NavBarComponent;
